import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import IntlProviderConfigured from 'i18n/IntlProviderConfigured';
import {
  ApolloProviderConfigured,
  KeycloakProviderConfigured,
  QueryProviderConfigured,
} from 'services/providers';
import MaterialLayout from 'components/Layout/MaterialLayout';
import ROUTES, { RenderRoutes } from 'navigation/routes';
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <KeycloakProviderConfigured>
      <ApolloProviderConfigured>
        <QueryProviderConfigured>
          <RecoilRoot>
            <IntlProviderConfigured>
              <BrowserRouter>
                <MaterialLayout>
                  <RenderRoutes routes={ROUTES} />
                </MaterialLayout>
              </BrowserRouter>
            </IntlProviderConfigured>
          </RecoilRoot>
        </QueryProviderConfigured>
      </ApolloProviderConfigured>
    </KeycloakProviderConfigured>
  );
}

export default App;
