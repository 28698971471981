import English from './en.json';
import Portuguese from './pt.json';
import Japanese from './ja.json';

interface ILanguage {
  [key: string]: {
    type: number;
    value: string;
  }[];
}

interface ILanguages {
  [key: string]: ILanguage;
}

const defaultLanguage: ILanguage = English;

const languages: ILanguages = { English, Portuguese, Japanese };

export * from './pt-BR';
export * from './ja-JP';
export { defaultLanguage };
export default languages;
