import { KeycloakInstance } from 'keycloak-js';

export interface KeycloakInstanceWithClaims extends KeycloakInstance {
  tokenParsed?: KeycloakTokenParsedWithClaims;
  idTokenParsed?: KeycloakTokenParsedWithClaims;
}

interface KeycloakTokenParsedWithClaims extends Keycloak.KeycloakTokenParsed {
  staffing_agency?: string;
  staffing_agency_id?: string;
  preferred_username?: string;
}

export const USER_ROLE = 'Haken-User';
export const STAFF_ROLE = 'Staff';
