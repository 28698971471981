import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    flexGrow: 1,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
}));
