import {
  createIntl,
  createIntlCache,
  FormattedMessage,
  MessageDescriptor,
} from 'react-intl';
import translations, {
  tablePortugueseStrings,
  syncfusionTablePortugueseStrings,
  tableJapaneseStrings,
  syncfusionTableJapaneseStrings,
} from './translations';
import Uppy_en_US from '@uppy/locales/lib/en_US';
import Uppy_pt_BR from '@uppy/locales/lib/pt_BR';
import Uppy_ja_JP from '@uppy/locales/lib/ja_JP';
import date_locale_en_US from 'date-fns/locale/en-US';
import date_locale_pt_BR from 'date-fns/locale/pt-BR';
import date_locale_ja from 'date-fns/locale/ja';

export type intlMessageType = string | JSX.Element;

export function intlMessage(
  messageDescriptor: MessageDescriptor,
  values?: {},
  useImperativeAPI?: boolean,
  localeForImperativeAPI?: string,
): intlMessageType {
  return reactIntl(
    messageDescriptor,
    values,
    useImperativeAPI,
    localeForImperativeAPI,
  );
}

export function intlMessageAsString(
  messageDescriptor: MessageDescriptor,
  locale?: string,
  values?: {},
): string {
  return reactIntl(messageDescriptor, values, true, locale) as string;
}

function reactIntl(
  messageDescriptor: MessageDescriptor,
  values?: {},
  useImperativeAPI?: boolean,
  localeForImperativeAPI?: string,
) {
  if (useImperativeAPI) {
    const locale = localeForImperativeAPI ?? navigator.language;
    const cache = createIntlCache();
    const intl = createIntl(
      {
        locale: locale,
        messages: translations[languageNameByLocale(locale)],
      },
      cache,
    );

    // Call imperatively
    const msg = intl.formatMessage(messageDescriptor, values);
    return msg;
  } else {
    return <FormattedMessage values={values} {...messageDescriptor} />;
  }
}

export function languageNameByLocale(localeCode: string) {
  switch (localeCode) {
    case 'en':
    case 'en-US':
      return 'English';
    case 'pt':
    case 'pt-BR':
      return 'Portuguese';
    case 'ja':
    case 'ja-JP':
      return 'Japanese';
    default:
      return 'English';
  }
}

export function selectTableLanguage(localeCode: string) {
  switch (localeCode) {
    case 'en':
    case 'en-US':
      return {};
    case 'pt':
    case 'pt-BR':
      return tablePortugueseStrings;
    case 'ja':
    case 'ja-JP':
      return tableJapaneseStrings;
    default:
      return {};
  }
}

export function selectSFTableLanguage(localeCode: string) {
  switch (localeCode) {
    case 'en':
    case 'en-US':
      return {};
    case 'pt':
    case 'pt-BR':
      return syncfusionTablePortugueseStrings;
    case 'ja':
    case 'ja-JP':
      return syncfusionTableJapaneseStrings;
    default:
      return {};
  }
}

export function selectUppyLanguage(localeCode: string) {
  switch (localeCode) {
    case 'en':
    case 'en-US':
      return Uppy_en_US;
    case 'pt':
    case 'pt-BR':
      return Uppy_pt_BR;
    case 'ja':
    case 'ja-JP':
      return Uppy_ja_JP;
    default:
      return Uppy_en_US;
  }
}

export function selectDateFnsLanguage(localeCode: string) {
  switch (localeCode) {
    case 'en':
    case 'en-US':
      return date_locale_en_US;
    case 'pt':
    case 'pt-BR':
      return date_locale_pt_BR;
    case 'ja':
    case 'ja-JP':
      return date_locale_ja;
    default:
      return date_locale_en_US;
  }
}

export function selectDateFormat(localeCode: string) {
  const localeFormat: { [key: string]: string } = {
    en: 'MM/dd/yyyy',
    'en-US': 'MM/dd/yyyy',
    pt: 'dd/MM/yyyy',
    'pt-BR': 'dd/MM/yyyy',
    ja: 'yyyy/MM/dd',
    'ja-JP': 'yyyy/MM/dd',
  };

  return localeFormat[localeCode] ?? localeFormat['en'];
}

export function selectMonthYearDateFormat(localeCode: string) {
  const localeFormat: { [key: string]: string } = {
    en: 'MMMM yyyy',
    'en-US': 'MMMM yyyy',
    pt: "MMMM 'de' yyyy",
    'pt-BR': "MMMM 'de' yyyy",
    ja: 'yyyy MMMM',
    'ja-JP': 'yyyy MMMM',
  };

  return localeFormat[localeCode] ?? localeFormat['en'];
}

export enum LANGUAGES_NAMES {
  English = 'English',
  Portuguese = 'Portuguese',
  Japanese = 'Japanese',
}

type TLanguages = keyof typeof LANGUAGES_NAMES;

export type TLanguagesObject = {
  [lang in TLanguages]: string;
};
