import {
  createMuiTheme,
  responsiveFontSizes,
  makeStyles,
} from '@material-ui/core/styles';
import { indigo, pink, green, amber } from '@material-ui/core/colors';

let theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: process.env.REACT_APP_ENVIRONMENT !== 'homolog' ? indigo : green,
    secondary: process.env.REACT_APP_ENVIRONMENT !== 'homolog' ? pink : amber,
  },
});

theme = responsiveFontSizes(theme);

const useStyle = makeStyles(() => ({
  root: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  formGroupBorder: {
    margin: '0.5rem',
    padding: '0.5rem',
    border: 'lightgrey solid 1px',
    borderRadius: '5px',
  },
  formGroupLabel: {
    transform: 'translate(10px, -15px) scale(1)',
    zIndex: 0,
    backgroundColor: 'white',
    paddingLeft: '5px',
    paddingRight: '5px',
    color: 'grey',
    position: 'absolute',
    width: 'max-content',
  },
}));

export { theme, useStyle };
