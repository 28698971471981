import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Button,
  Grid,
  Icon,
  Link,
  MenuItem,
  Select,
} from '@material-ui/core';
import useStyles from './styles';
import { IntlContext } from 'i18n/IntlProviderConfigured';
import { Link as RouterLink } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakInstanceWithClaims } from 'services/keycloak';
import { intlMessage } from 'i18n';
import messages from './Header.messages';

export default function Header() {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const keyc = useKeycloak();
  const keycloak: KeycloakInstanceWithClaims = keyc.keycloak;

  function login() {
    keycloak.login({
      redirectUri: window.location.origin,
    });
  }
  function logout() {
    keycloak.logout({
      redirectUri: window.location.origin,
    });
  }

  function changeLanguage(
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) {
    intlContext.changeLanguage(e.target.value as string);
  }

  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar>
        <Grid container>
          <Grid item sm={2} xs>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <Link component={RouterLink} to="/" className={classes.link}>
                <Icon>home</Icon>
                StaMane
                {process.env.REACT_APP_ENVIRONMENT === 'homolog' &&
                  ' - Preview'}
              </Link>
            </Typography>
          </Grid>
          <Grid item sm={10} xs container justify="flex-end">
            <Select value={intlContext.locale} onChange={changeLanguage}>
              <MenuItem value={'en-US'}>English</MenuItem>
              <MenuItem value={'pt-BR'}>Português</MenuItem>
              <MenuItem value={'ja-JP'}>日本語</MenuItem>
            </Select>
            {/* </Grid>
          <Grid item sm={3} xs={12} zeroMinWidth> */}
            <Box marginX="1rem">
              <Typography variant="h6" noWrap>
                {/* <Link
                component={RouterLink}
                to=""
                onClick={keycloak.accountManagement}
              > */}
                {keycloak.tokenParsed?.preferred_username ?? ''}
                {/* </Link> */}
              </Typography>
            </Box>
            {/* </Grid>
          <Grid item md={1} sm={2} xs={12}> */}
            {keycloak.authenticated && (
              <Button variant="outlined" onClick={logout}>
                {intlMessage(messages.logoutButton)}
              </Button>
            )}
            {!keycloak.authenticated && (
              <Button variant="outlined" onClick={login}>
                {intlMessage(messages.loginButton)}
              </Button>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
