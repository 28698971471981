import { Skeleton } from '@material-ui/lab';

interface LoadingComponentProps {}

const LoadingComponent = (props: LoadingComponentProps) => {
  return (
    <>
      <Skeleton animation="wave" height={40} />
      <Skeleton animation="wave" height={40} />
      <Skeleton animation="wave" height={40} />
    </>
  );
};

export default LoadingComponent;
