import React from 'react';
import { Paper, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import Header from './Header';
import Footer from './Footer';

import { theme, useStyle } from './styles';
import { useKeycloak } from '@react-keycloak/web';
import LoadingComponent from './LoadingComponent';

interface MaterialLayoutProps {
  children: React.ReactNode;
}

export default function MaterialLayout(props: MaterialLayoutProps) {
  const { children } = props;
  const classes = useStyle();
  const kc = useKeycloak();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <CssBaseline />
        <Header />
        <div className={classes.root}>
          <Paper className={classes.paper}>
            {!kc.initialized && <LoadingComponent />}
            {kc.initialized && children}
          </Paper>
        </div>
        <Footer />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
