import { Redirect } from 'react-router-dom';
import { useAuthUtils } from 'utils/auth/auth';

function RootPage() {
  const { isLoggedIn } = useAuthUtils();

  if (isLoggedIn) return <Redirect to={'/home'} />;

  return <div>Login, please</div>;
}

export default RootPage;
