import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import RootPage from 'pages/RootPage';
import LoadingComponent from 'components/Layout/LoadingComponent';
const HomePage = lazy(() => import('pages/home'));
const PageNotFound = lazy(() => import('pages/404'));
const WorkerInfoPage = lazy(() => import('pages/worker/info'));
const WorkerListPage = lazy(() => import('pages/worker/list'));
const WorkerSearchPage = lazy(() => import('pages/worker/search'));
const DataSharePage = lazy(() => import('pages/data_share'));
const JobOpportunityInfoPage = lazy(() =>
  import('pages/job_opportunities/info'),
);
const JobOpportunityListPage = lazy(() =>
  import('pages/job_opportunities/list'),
);
const CompanyInfoPage = lazy(() => import('pages/company/info'));
const CompanyListPage = lazy(() => import('pages/company/list'));
const EmployeeInfoPage = lazy(() => import('pages/employee/info'));
const EmployeeListPage = lazy(() => import('pages/employee/list'));

export interface IRoute {
  path: string;
  key?: string;
  exact?: boolean;
  routes?: IRoute[];
  component: React.ElementType;
}

const ROUTES: IRoute[] = [
  {
    path: '/',
    key: 'APP',
    component: RenderRoutes,
    routes: [
      {
        path: '/',
        key: 'APP_ROOT',
        exact: true,
        component: RootPage,
      },
      {
        path: '/home',
        key: 'APP_HOME',
        exact: true,
        component: HomePage,
      },
      {
        path: '/worker',
        key: 'WORKER_PAGE',
        exact: false,
        component: RenderRoutes,
        routes: [
          // {
          //   path: '/worker',
          //   key: 'WORKER_ROOT',
          //   exact: true,
          //   component: RouteMenu,
          // },
          {
            path: '/worker/info',
            key: 'WORKER_INFO',
            exact: true,
            component: WorkerInfoPage,
          },
          {
            path: '/worker/list',
            key: 'WORKER_LIST',
            exact: true,
            component: WorkerListPage,
          },
          {
            path: '/worker/search',
            key: 'WORKER_ADVANCED_SEARCH',
            exact: true,
            component: WorkerSearchPage,
          },
        ],
      },
      {
        path: '/data_share',
        key: 'DATA_SHARE_PAGE',
        exact: true,
        component: DataSharePage,
      },
      {
        path: '/job_opportunities',
        key: 'VACANCY_PAGE',
        exact: false,
        component: RenderRoutes,
        routes: [
          {
            path: '/job_opportunities/info',
            key: 'VACANCY_INFO',
            exact: true,
            component: JobOpportunityInfoPage,
          },
          {
            path: '/job_opportunities/list',
            key: 'VACANCY_LIST',
            exact: true,
            component: JobOpportunityListPage,
          },
        ],
      },
      {
        path: '/companies',
        key: 'COMPANY_PAGE',
        exact: false,
        component: RenderRoutes,
        routes: [
          {
            path: '/companies/info',
            key: 'COMPANY_INFO',
            exact: true,
            component: CompanyInfoPage,
          },
          {
            path: '/companies/list',
            key: 'COMPANY_LIST',
            exact: true,
            component: CompanyListPage,
          },
        ],
      },
      {
        path: '/employee',
        key: 'EMPLOYEE_PAGE',
        exact: false,
        component: RenderRoutes,
        routes: [
          {
            path: '/employee/info',
            key: 'EMPLOYEE_INFO',
            exact: true,
            component: EmployeeInfoPage,
          },
          {
            path: '/employee/list',
            key: 'EMPLOYEE_LIST',
            exact: true,
            component: EmployeeListPage,
          },
        ],
      },
    ],
  },
];

export default ROUTES;

/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */
function RouteWithSubRoutes({
  path,
  exact,
  component: Component,
  routes,
}: IRoute) {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => <Component {...props} routes={routes} />}
    />
  );
}

interface RenderRoutesProps {
  routes?: IRoute[];
}

/**
 * Use this component for any new section of routes (any config object that has a "routes" property)
 */
export function RenderRoutes(props: RenderRoutesProps) {
  const { routes } = props;

  return (
    <>
      <Suspense fallback={<LoadingComponent />}>
        <Switch>
          {routes?.map((route) => {
            return (
              <RouteWithSubRoutes key={route.key} routes={routes} {...route} />
            );
          })}
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    </>
  );
}
