export const tablePortugueseStrings = {
  body: {
    emptyDataSourceMessage: 'Nenhum registro para exibir',
    addTooltip: 'Adicionar',
    deleteTooltip: 'Deletar',
    editTooltip: 'Editar',
    filterRow: {
      filterPlaceHolder: '',
      filterTooltip: 'Filtrar',
    },
    editRow: {
      deleteText: 'Você tem certeza em deletar essa linha?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Salvar',
    },
  },
  grouping: {
    placeholder: 'Arraste cabeçalhos...',
    groupedBy: 'Agrupado por:',
  },
  header: {
    actions: 'Ações',
  },
  toolbar: {
    addRemoveColumns: 'Adicionar ou remover colunas',
    showColumnsTitle: 'Mostrar colunas',
    showColumnsAriaLabel: 'Mostrar colunas',
    exportTitle: 'Exportar',
    exportAriaLabel: 'Exportar',
    exportName: 'Exportar como CSV',
    searchTooltip: 'Pesquisar',
    searchPlaceholder: 'Pesquisar',
    nRowsSelected: (nRows: number) =>
      nRows === 1
        ? '1 registro selecionado'
        : `${nRows} registros selecionados`,
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'linhas',
    labelRowsPerPage: 'Linhas por página:',
    firstAriaLabel: 'Primeira página',
    firstTooltip: 'Primeira página',
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',
    nextAriaLabel: 'Próxima página',
    nextTooltip: 'Próxima página',
    lastAriaLabel: 'Última página',
    lastTooltip: 'Última página',
  },
};

export const syncfusionTablePortugueseStrings = {
  'pt-BR': {
    grid: {
      EmptyDataSourceError:
        'A fonte de dados não pode ser vazia no carregamento inicial pois as colunas são geradas a partir da fonte de dados em AutoGenerate Column Grid',
      EmptyRecord: 'Nenhum registro para exibir',
      Item: 'registro',
      Items: 'registros',
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Primeira página',
      lastPageTooltip: 'Última página',
      nextPageTooltip: 'Próxima página',
      nextPagerTooltip: 'Próximo grupo de páginas',
      previousPageTooltip: 'Página anterior',
      previousPagerTooltip: 'Grupo de páginas anterior',
      totalItemInfo: '({0} registro)',
      totalItemsInfo: '({0} registros)',
      pagerDropDown: 'Registros por página',
      All: 'Todos',
    },
  },
};
