import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
export const keycloakInstance = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_SERVER_URI,
  realm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'unset ENVVAR REALM',
  clientId:
    process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'unset ENVVAR CLIENT_ID',
});

const initOptions = {
  onLoad: 'check-sso', // check-sso ou login-required
  pkceMethod: 'S256',
};

export function KeycloakProviderConfigured(props: {
  children: React.ReactNode;
}) {
  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      initOptions={initOptions}
    >
      {props.children}
    </ReactKeycloakProvider>
  );
}
