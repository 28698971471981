export const tableJapaneseStrings = {
  body: {
    emptyDataSourceMessage: '表示出来るレコードが有りません',
    addTooltip: '追加',
    deleteTooltip: '削除',
    editTooltip: '編集',
    filterRow: {
      filterPlaceHolder: '',
      filterTooltip: 'フィルター',
    },
    editRow: {
      deleteText: 'この行を削除してもよろしいですか？?',
      cancelTooltip: 'キャンセル',
      saveTooltip: '保存',
    },
  },
  grouping: {
    placeholder: 'ヘッダーをドラッグ...',
    groupedBy: 'グループ　:',
  },
  header: {
    actions: '行動',
  },
  toolbar: {
    addRemoveColumns: '列を追加または削除する',
    showColumnsTitle: '列を表示する',
    showColumnsAriaLabel: '列を表示する',
    exportTitle: 'エクスポート',
    exportAriaLabel: 'エクスポート',
    exportName: 'CSVファイルとしてエクスポート',
    searchTooltip: '検索',
    searchPlaceholder: '検索',
    nRowsSelected: (nRows: number) => `選択されたレコードは　${nRows}`,
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} of {count}',
    labelRowsSelect: '行',
    labelRowsPerPage: 'ページあたりの行数:',
    firstAriaLabel: '1ページ目',
    firstTooltip: '1ページ目',
    previousAriaLabel: '前のページ',
    previousTooltip: '前のページ',
    nextAriaLabel: '次のページ',
    nextTooltip: '次のページ',
    lastAriaLabel: '最後のページ',
    lastTooltip: '最後のページ',
  },
};

export const syncfusionTableJapaneseStrings = {
  'ja-JP': {
    grid: {
      EmptyDataSourceError:
        'DataSource must not be empty at initial load since columns are generated from dataSource in AutoGenerate Column Grid',
      EmptyRecord: '表示出来るレコードが有りません',
      Item: 'レコード',
      Items: 'レコード',
    },
    pager: {
      currentPageInfo: '{0} of {1} ページ',
      firstPageTooltip: '1ページ目',
      lastPageTooltip: '最後のページ',
      nextPageTooltip: '次のページ',
      nextPagerTooltip: '次のグループページ',
      previousPageTooltip: '前のページ',
      previousPagerTooltip: '前のグループページ',
      totalItemInfo: '({0} レコード)',
      totalItemsInfo: '({0} レコード)',
      pagerDropDown: 'ページあたりの行数',
      All: '全体',
    },
  },
};
