import { useKeycloak } from '@react-keycloak/web';
import { KeycloakInstanceWithClaims } from 'services/keycloak';

export function isUserHakenStaff(keycloak: KeycloakInstanceWithClaims) {
  return Boolean(keycloak?.tokenParsed?.staffing_agency);
}

export function getUserGroup(keycloak: KeycloakInstanceWithClaims) {
  return keycloak?.tokenParsed?.staffing_agency;
}

export function getUserName(keycloak: KeycloakInstanceWithClaims) {
  return keycloak?.tokenParsed?.preferred_username;
}

export function getToken(keycloak: KeycloakInstanceWithClaims) {
  return keycloak?.token;
}

export function useAuthUtils() {
  const kc = useKeycloak();
  const isUserHakenStaff = Boolean(
    (kc.keycloak as KeycloakInstanceWithClaims)?.tokenParsed?.staffing_agency,
  );

  const isLoggedIn = kc.initialized && kc.keycloak?.authenticated;

  const isInitialized = kc.initialized;

  return { isUserHakenStaff, isLoggedIn, isInitialized };
}
