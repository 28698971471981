import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { keycloakInstance } from 'services/providers';
import { isUserHakenStaff } from 'utils/auth';
import { STAFF_ROLE, USER_ROLE } from 'services/keycloak';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_URI,
});

const authLink = setContext((_, { headers }) => {
  const { token } = keycloakInstance;

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'x-hasura-role': isUserHakenStaff(keycloakInstance)
        ? STAFF_ROLE
        : USER_ROLE,
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const ApolloProviderConfigured = (props: {
  children: React.ReactNode;
}) => {
  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};
