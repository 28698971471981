import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import translations from './translations';
import { languageNameByLocale } from '.';

interface IntlContextType {
  locale?: string;
  changeLanguage: (e: string) => void;
}

const local = navigator.language;
const language = languageNameByLocale(local);
const IntlContext = React.createContext<IntlContextType>({
  locale: local,
  changeLanguage: (e: string) => {},
});

interface IntlProviderConfiguredProps {
  children: React.ReactNode;
}

function IntlProviderConfigured(props: IntlProviderConfiguredProps) {
  const [locale, setLocale] = useState(local);
  const [lang, setLang] = useState(language);

  function changeLanguage(e: string) {
    // const newLocale = e.target.value;
    const newLocale = e;
    setLocale(newLocale);
    setLang(languageNameByLocale(newLocale));
  }

  return (
    <IntlContext.Provider value={{ locale, changeLanguage }}>
      <IntlProvider
        locale={locale}
        defaultLocale="en"
        messages={translations[lang]}
      >
        {props.children}
      </IntlProvider>
    </IntlContext.Provider>
  );
}

export { IntlContext, languageNameByLocale as selectLanguage };
export default IntlProviderConfigured;
