import axios from 'axios';
import { keycloakInstance } from 'services/providers';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PROPOSAL_SERVER_URI,
});

axiosInstance.interceptors.request.use(
  (configuration) => {
    // Do something before request is sent
    const newConfig = { ...configuration };
    newConfig.headers.common.Authorization = `Bearer ${keycloakInstance.token}`;
    return newConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export default axiosInstance;
