import { defineMessages } from 'react-intl';

const messages = defineMessages({
  loginButton: {
    defaultMessage: 'Log In',
    description: 'Login button',
  },
  logoutButton: {
    defaultMessage: 'Log Out',
    description: 'Logout button',
  },
});

export default messages;
